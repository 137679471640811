import { tmrResourceFormController } from 'tmr/controllers/resourceForm/resourceForm'
import templateUrl from './form.html'
import _ from 'lodash'
import dayjs from 'dayjs'

/**
 * Administer Session
 *
 * @namespace routes.ReportSessionFormAdd
 * @type {TmrRoute}
 */
let route = {
  component: 'tmrAdminSessionForm',
  accessControl: ['Loglavorazione.capability.admin'],
  resolve: {
    operazioni: /** @ngInject */ Operazione =>
      Operazione.query({ orderBy: 'CODE', asc: 'true' }).$promise,
    economie: /** @ngInject */ Economia =>
      Economia.query({ orderBy: 'CODE', asc: 'true' }).$promise,
    users: /** @ngInject */ User =>
      User.query({ orderBy: 'USERNAME', asc: 'true' }).$promise,
  },
}

/**
 * Administer Session
 *
 * @namespace routes.ReportSessionFormEdit
 * @type {TmrRoute}
 */
let editRoute = {
  ...route,
  name: 'report.sessions.view.edit',
}

/**
 * Administer Session
 *
 * @namespace routes.ReportSessionFormEdit
 * @type {TmrRoute}
 */
let addRoute = {
  ...route,
  name: 'report.sessions.add',
}

export const routes = [addRoute, editRoute]

/**
 * Customer add/edit form component.
 *
 * @namespace tmrAdminSessionForm
 * @type {ngComponent}
 */
export const tmrAdminSessionForm = {
  ngType: 'component',
  templateUrl,
  controller: 'tmrAdminSessionFormController',
  bindings: {
    resource: '<',
    toast: '<',
    operazioni: '<',
    economie: '<',
    users: '<',
  },
}

/**
 * Controller of component {@link tmrAdminSessionForm}.
 *
 * @type {ngController}
 */
export class tmrAdminSessionFormController extends tmrResourceFormController {
  constructor(
    $scope,
    $state,
    $stateParams,
    TmrInvalidFormError,
    TmrToast,
    User,
    Operazione,
    Economia,
    ProductionOrderRow,
    Session
  ) {
    'ngInject'
    super($scope, $state, $stateParams, TmrInvalidFormError, TmrToast)
    Object.assign(this, {
      User,
      Operazione,
      Economia,
      ProductionOrderRow,
      Session,
    })

    this.statusArray = ['CANCELED', 'COMPLETED']
  }
  $onInit() {
    super.$onInit()
    console.log(this.resource)
    this.operazione = this.operazioni.find(
      v => v.code === this.resource.codiceOperazione
    )
    this.economia = this.economie.find(
      v => v.code === this.resource.codiceMotivoEconomia
    )
    this.orderCode = this.resource.orderCode
    this.startEndDate = {
      from: new Date(this.resource.startDate).getTime(),
      to: new Date(this.resource.endDate).getTime(),
    }
    this.getRows(false)
    this.selectedRows = {}
    this.resource.rows.forEach(element => {
      _.set(this.selectedRows, `${element.size}.${element.progressive}`, {
        selected: true,
      })
    })
  }
  filterUsers(value) {
    return this.users.filter(
      u =>
        u.email.toLowerCase().includes(value.toLowerCase()) ||
        u.username.toLowerCase().includes(value.toLowerCase()) ||
        _.get(u.attributes, 'matricola', []).includes(value.toLowerCase())
    )
  }
  filterOperazioni(value) {
    return this.operazioni.filter(op =>
      op.$getLabel().toLowerCase().includes(value.toLowerCase())
    )
  }
  filterEconomie(value) {
    return this.economie.filter(eco =>
      eco.$getLabel().toLowerCase().includes(value.toLowerCase())
    )
  }
  setOperazione(value) {
    this.resource.codiceOperazione = _.get(value, 'code', null)
    this.getRows(false)
  }
  setEconomia(value) {
    this.resource.codiceMotivoEconomia = _.get(value, 'code', null)
  }
  setStatus(value) {
    this.resource.status = value
  }
  setOrderCode(value) {
    console.log('ORDER CODE', value)
    this.resource.orderCode = value
  }

  save() {
    this.resource.commessa = this.resource.orderCode
    console.log('RESOURCE', this.resource)
    if (this.op === 'add') {
      return this.resource.$adminSave()
    }
    return this.resource.$adminUpdate()
  }
  setDates() {
    this.resource.startDate = this.convertToIso(this.startEndDate.from)
    this.resource.endDate = this.convertToIso(this.startEndDate.to)
  }
  convertToIso(value) {
    if (value) {
      return dayjs(parseInt(value)).format('YYYY-MM-DDTHH:mm:ss.SSS')
    }
    return null
  }
  getOrderRows(pacco) {
    return this.ProductionOrderRow.search({
      value: pacco,
    }).$promise.then(rows => {
      return _.chain(rows).map('pacco').uniq().value()
    })
  }
  getRows(wipeRows = true) {
    if (this.resource.orderCode && this.resource.codiceOperazione) {
      this.selectedRows = {}
      if (wipeRows) this.resource.rows = []
      let orders = this.ProductionOrderRow.query({
        pacco: this.resource.orderCode,
      }).$promise
      let worked = this.Session.rows({
        orderCode: this.resource.orderCode,
        codiceOperazione: this.resource.codiceOperazione,
      }).$promise
      Promise.all([orders, worked]).then(([orders, worked]) => {
        _.remove(worked, w => {
          return this.resource.rows.find(
            r => r.size === w.size && r.progressive === w.progressive
          )
        })
        _.remove(orders, el => {
          return worked.find(
            w =>
              w.size === el.product.size.value &&
              w.progressive === el.progressive
          )
        })
        this.rows = orders
        this.$scope.$apply()
      })
    }
  }

  toggleRow() {
    let selected = []
    _.forOwn(this.selectedRows, (obj, size) => {
      _.forOwn(obj, (a, progr) => {
        if (a.selected) {
          selected.push({ size: size, progressive: progr })
        }
      })
    })
    this.resource.rows = selected
  }
}
